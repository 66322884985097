import React from 'react'
import Banner from '../Components/Banner'
import Portfolio from '../Components/Portfolio'
import Web_pricing from '../Components/Web_pricing'
import Call_now from '../Components/Call_now'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'

const bannerImg = require("../img/banner_img_6.jpg");


const image = {
  backgroundImage: `linear-gradient(45deg, #12131999, #12131999), url(${bannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
export default function Web_design() {
  return (
    <>
 <Motion>


    <Banner text1={"MOBILE & WEB APPLICATION"} text2={"Mobile use is growing faster than all of Google’s internal predictions"} text3={"Erich Schmidt"} text4={"Website"} image={"linear-gradient(45deg, #12131999, #12131999), url(./img/banner_img_6.jpg)"} />
    <Portfolio/>
    <Web_pricing/>
    <Call_now/>
 </Motion>
    
    </>
  )
}
