import React from 'react'
import Banner from '../Components/Banner'
import Contact_form from '../Components/Contact_form'
import Motion from '../Components/Wrapper/Motion'

export default function Contact() {
  return (
    <>
    <Motion>

        <Banner text1={"LET'S TALK ABOUT YOUR PROJECT."} text2={"Feel free to ask me any question or let’s do to talk about our future collaboration."} text3={"Erich Schmidt"} text4={"Website"} image={"linear-gradient(45deg, #12131999, #12131999),url(./img/contact_img.jpg)"} />
        <Contact_form/>
    </Motion>
      

    </>
  )
}
