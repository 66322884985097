import React, { useEffect, useState } from "react";
import CustomLink from "./Custom/CustomLink";



export default function Footer() {
  const [waMessage,setWaMessage]= useState("")
  const [currentTime, setCurrentTime] = useState('')

  
  function handleClick() {
    const whatsappChat = document.getElementById('whatsapp-chat');
    if (whatsappChat.classList.contains('hide')) {
      whatsappChat.classList.remove('hide');
      whatsappChat.classList.add('show');
    } else {
      whatsappChat.classList.remove('show');
      whatsappChat.classList.add('hide');
    }
  setWaMessage("")

  }




  function onChange(event) {
    setWaMessage(event.target.value)

    
  }
  function sendMessage() {
    if (waMessage) {
      const phoneNumber = '+441444390564'; 
      const encodedMessage = encodeURIComponent(waMessage);
      const newPageUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
     
      window.open(newPageUrl, '_blank');
    }
  }
  


  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const formattedTime = formatTime(date);
      setCurrentTime(formattedTime);
    };

    updateTime();

    const intervalId = setInterval(updateTime, 60000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
  return (
    
    <>
      <footer className="sub-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="item">
                <div className="logo mb-25">
                  <img src={require("../img/logo-light.png")} alt="" />
                </div>
                <p>
                  We help businesses to grow from a small seed to a flourishing
                  fruitful tree with the help of our skilful and dedicated
                  individuals.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item md-mb50">
                <div className="title">
                  <h5>Contact Us</h5>
                </div>
                <ul>
                  <li>
                    <span className="icon pe-7s-map-marker"></span>
                    <div className="cont">
                      <h6>Office Address</h6>
                      <p id="address_c">
                        182-184 High Street North, East Ham, London E6 2JA. UK
                      </p>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-mail"></span>
                    <div className="cont">
                      <h6>Email Us</h6>
                      <p>
                        <a href="mailto:info@theinceptives.com">
                          info@theinceptives.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-call"></span>
                    <div className="cont">
                      <h6>Call Us</h6>
                      <p>
                        <a href="tel:+44 1444 390564" className="phone_c">
                          (+44) 1444 390564
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="item">
                <div className="social">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/inceptives/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a target="_blank" href="https://twitter.com/Inceptives1">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://instagram.com/theinceptives?igshid=MDE2OWE1N2Q="
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/theinceptives"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
                <div className="copy-right">
                  <p>
                    © 2023, Made with passion by
                    <a href="https://theinceptives.com/">The Inceptives</a>.
                  </p>
                </div>
                <div className="d-flex terms">
                  <CustomLink to="/Terms" id="root" className="mr-1" style={{cursor:'pointer'}}>
                    <small>
                    Terms & Conditions
                    </small>
                  </CustomLink>
                  <CustomLink to="/Privacy" id="root" className="ml-1" style={{cursor:'pointer'}}>
                   <small>
                   Privacy & Policy
                    </small>
                  </CustomLink>
                </div>
               
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="imgwrp">
                <ul>
                  <li>
                    <a href="https://www.trustpilot.com/review/theinceptives.com" target="_blank">
                      <img
                        src={require("../img/trustpilot_logo.webp")}
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <img src={require("../img/partner1.webp")} alt="" />
                  </li>
                  <li>
                    <img src={require("../img/partner5.png")} alt="" />
                  </li>
                  <li>
                    <img src={require("../img/partner2.webp")} alt="" />
                  </li>
                  <li>
                    <a
                      href="https://www.bark.com/en/gb/company/the-inceptives/Ez4jy/"
                      target="_blank"
                    >
                      <img src={require("../img/bark-logo-gold.png")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src={require("../img/reviews.io.webp")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src={require("../img/sitejabber.png")} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="text-center">
                  <img
                    src={require("../img/footer.webp")}
                    className="img-fluid visa_img"
                    width=""
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="phone-call cbh-phone cbh-green cbh-show cbh-static"
          id="clbh_phone_div"
        >
          <a
            id="WhatsApp-button"
            href="https://wa.me/message/WKUOKF47RO4RC1"
            target="_blank"
            className="phoneJs"
            title="WhatsApp 360imagem"
          >
            <div className="cbh-ph-circle"></div>
            <div className="cbh-ph-circle-fill"></div>
            <div className="cbh-ph-img-circle1"></div>
          </a>
        </div>
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@theinceptives.com"
          className="mail_main"
        >
          <div className="envelope">
            <div className="envelope-front"></div>
            <div className="envelope-flap"></div>
            <div className="envelope-flap moves"></div>
            <div className="letter"></div>
          </div>
        </a>
        {/* <div className="floatbutton">
        <a href="" className="clickbutton">
          <div className="crossplus">
            Chat <span className="px-1">On</span> WhatsApp
          </div>
        </a>
      </div> */}
        <div id="whatsapp-chat" className="hide">
          <div className="header-chat">
            <div className="head-home">
              <div className="info-avatar">
                <img src={"/img/whatspp_inceptives.jpg"} />
              </div>
              <p>
                <span className="whatsapp-name">The inceptives</span>
                <br />
                <small>Typically replies within an hour</small>
              </p>
            </div>
            <div className="get-new hide">
              <div id="get-label"></div>
              <div id="get-nama"></div>
            </div>
          </div>
          <div className="home-chat"></div>
          <div className="start-chat">
            <div
              pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
              className="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
            >
              <div className="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                <div
                  
                  className="WhatsappDots__Component-pks5bf-0 eJJEeC"
                >
                  <div className="WhatsappDots__ComponentInner-pks5bf-1 hFENyl">
                    <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 ixsrax"></div>
                    <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 dRvxoz"></div>
                    <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 kXBtNt"></div>
                  </div>
                </div>
                <div
                  
                  className="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                >
                  <div className="WhatsappChat__Author-sc-1wqac52-3 bMIBDo">
                    The inceptives
                  </div>
                  <div className="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                    Hi there 👋
                    <br />
                    <br />
                    How can I help you?
                  </div>
                  <div className="WhatsappChat__Time-sc-1wqac52-5 cqCDVm time_whatsapp">{currentTime} </div>
                </div>
              </div>
            </div>

            <div className="blanter-msg">
              <textarea
                id="chat-input"
                placeholder="Write a response"
                maxlength="120"
                value={waMessage}
                onChange={onChange}
                row="1"
              ></textarea>
              <a  id="send-it" style={{cursor:'pointer'}}   onClick={sendMessage}>
                <svg viewBox="0 0 448 448">
                  <path d="M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z" />
                </svg>
              </a>
            </div>
          </div>
          <div id="get-number"></div>
          <a  className="close-chat" style={{cursor:'pointer'}} onClick={handleClick}>
            ×
          </a>
        </div>
        <a
          className="blantershow-chat"
          onClick={handleClick}
          title="Show Chat"
          style={{cursor:'pointer'}}
        >
          <svg width="20" viewBox="0 0 24 24">
            <defs />
            <path
              fill="#eceff1"
              d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"
            />
            <path
              fill="#4caf50"
              d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"
            />
            <path
              fill="#fafafa"
              d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"
            />
          </svg>
          Chat with Us
        </a>
      </footer>
    </>
  );
}
