import React from 'react'
import Banner from '../Components/Banner'
import Pricing from '../Components/Pricing'
import Portfolio from '../Components/Portfolio'
import Call_now from '../Components/Call_now'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'

export default function Logo_design() {
  return (
    <>
   <Motion>


        <Banner text1={"Logo & Design"} text2={"There are three responses to a piece of design – yes, no, and WOW! Wow is the one to aim for"} text3={"Milton Glaser"} text4={"Graphic"} image={"linear-gradient(45deg, #12131999, #12131999), url(./img/banner_img_8.jpg)"}/>
        <Portfolio/>
        <Pricing/>
        <Call_now/>
   
   </Motion>
    </>
  )
}
