import React from 'react';
import { motion } from 'framer-motion';

export default function Motion({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1.2 } }}
      exit={{ opacity: 0, transition: { duration: 1.2 } }}
    >
      {children}
    </motion.div>
  );
}
