import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

// import "swiper/css/navigation";
// import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Autoplay } from 'swiper';

export default function Hero_section() {
  return (
    <>
      <header className="slider slider-prlx fixed-slider text-center">
        <div className="swiper-container parallax-slider">
          <div className="swiper-wrapper">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, A11y, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
             
            
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                {" "}
                <div
                  className="bg-img valign hero_1"
                  data-background="img/banner_img_4.jpg"
                  data-overlay-dark="6"
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-9 col-md-9">
                        <div className="caption center mt-30">
                          <h1 className="slider_content">
                            "Only those who will risk going too far can possibly
                            find out how far one can go.”
                            <br />
                            <span className="color-font"> T.S. Eliot.</span>
                          </h1>

                          <a href="#0" className="butn bord curve mt-30">
                            <span>Look More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bg-img valign hero_2"
                  data-background="img/banner_img_5.jpg"
                  data-overlay-dark="6"
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-9 col-md-9">
                        <div className="caption center mt-30">
                          <h1>
                            Graphic{" "}
                            <span className="color-font">Design & Animation</span>
                          </h1>
                          <p>
                            We create creative designs that speak in the term of
                            marketing or persuading for a business.
                          </p>
                          <a href="#0" className="butn bord curve mt-30">
                            <span>Look More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bg-img valign hero_3"
                  data-background="img/banner_img_1.jpg"
                  data-overlay-dark="6"
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-10">
                        <div className="caption center mt-30">
                          <h1>
                            Website & Mobile{" "}
                            <span className="color-font">Application</span>
                          </h1>
                          <p>
                            Our geeky devs are ready to take off your business
                            on another level with 24/7 customer support of a
                            project manager with you.
                          </p>
                          <a href="#0" className="butn bord curve mt-30">
                            <span>Look More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="bg-img valign hero_4"
                  data-background="img/banner_img_3.jpg"
                  data-overlay-dark="6"
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 col-md-9">
                        <div className="caption center mt-30">
                          <h1>
                            Digital <span className="color-font">Marketing</span>
                          </h1>
                          <p>
                            Ignoring online marketing is like opening a business
                            but not telling anyone.
                          </p>
                          <a href="#0" className="butn bord curve mt-30">
                            <span>Look More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              ...
            </Swiper>
          </div>
          <div className="setone setwo">
            <div className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer">
              <i className="fas fa-chevron-right"></i>
            </div>
            <div className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer">
              <i className="fas fa-chevron-left"></i>
            </div>
          </div>
          <div className="swiper-pagination top botm "></div>
          <div className="social-icon">
            <a target="_blank" href="https://www.facebook.com/inceptives/">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a target="_blank" href="https://twitter.com/Inceptives1">
              <i className="fab fa-twitter"></i>
            </a>
            <a
              target="_blank"
              href="https://instagram.com/theinceptives?igshid=MDE2OWE1N2Q="
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/theinceptives"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
