import React from 'react'
import WOW from 'wowjs';


export default function About_company() {
  return (
    <>
        <div className="about section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="img-mons sm-mb30">
                            <div className="row">
                                <div className="col-md-5 cmd-padding valign">
                                    <div className="img1" data-wow-delay=".5s">
                                        <img src={require("../img/intro/1.jpg")} alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-7 cmd-padding">
                                    <div className="img2" data-wow-delay=".3s">
                                        <img src={require("../img/intro/3.jpg")} alt=""/>
                                    </div>
                                    <div className="img3" data-wow-delay=".8s">
                                        <img src={require("../img/intro/2.jpg")} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1 valign">
                        <div className="content">
                            <div className="sub-title">
                                <h6>About Us</h6>
                            </div>
                            <h3 className="main-title wow" data-splitting>We are more than just <br/> a digital agency.</h3>
                            <p className="wow txt" data-splitting>We help businesses to grow from a small seed to a
                                flourishing fruitful tree with the help of our
                                skilful and dedicated individuals.
                            </p>
                            <div className="ftbox mt-30">
                                <ul>
                                    <li className="wow fadeIn" data-wow-delay=".3s">

                                        <span>
                                            <img className="img-fluid" src={require("../img/icons/24 7 customer support.png")} alt=""/>
                                        </span>
                                        <h6>24/7<br/> Customer Support</h6>

                                    </li>
                                    <li className="space wow fadeIn" data-wow-delay=".5s">
                                        <span>
                                            <img className="img-fluid"
                                                src={require("../img/icons/Free Modifications till satisfaction.png")} alt=""/>
                                        </span>
                                        <h6>Free Modifications <br/> till satisfaction</h6>

                                    </li>
                                    <li className="wow fadeIn" data-wow-delay=".8s">
                                        <span>
                                            <img className="img-fluid" src={require("../img/icons/Quick turnaround Time.png")} alt=""/>
                                        </span>
                                        <h6>Quick <br/> Turnaround Time</h6>

                                    </li>
                                    <li className="space wow fadeIn" data-wow-delay=".5s">
                                    <span>
                                            <img className="img-fluid"
                                                src={require("../img/icons/Free Modifications till satisfaction.png")} alt=""/>
                                        </span>
                                        <h6>Industry Proven <br/> Professionals</h6>

                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
