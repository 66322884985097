import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { color } from 'framer-motion';

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height:300,
  backgroundColor: 'white',
  outline: 'none',
  border: '1px solid #ccc',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '4px',
  padding: '16px',
};

export default function BasicModal({open,handleOpen,setOpen, message}) {
  
  
  const handleClose = () => setOpen(false);

  return (
    <div>
      
      <Modal
        open={open}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
            <Box display={"flex"} justifyContent={"center"} mt={1}>
            <img src={message.error ? 'https://img.freepik.com/premium-vector/red-cross-mark-icon-negative-choice-symbol-sign-app-button_744955-339.jpg' :'https://media.tenor.com/0AVbKGY_MxMAAAAM/check-mark-verified.gif'} style={{width:"70px"}}/>

            </Box>
          <Typography style={{color:message.error ? "red" : "green"}} fontSize={"2rem"} id="modal-modal-title" variant="h6" component="h1" textAlign={"center"}>
           {message.error ? "Error" : "Success" }
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign={"center"}>
           {message.mes}
          </Typography>
          <Box display={"flex"} justifyContent={"center"} mt={3}>
          <button className='butn dark'   onClick={handleClose}>
          <span>Continue</span>
          </button>
          </Box>
          
        </Box>
      </Modal>
    </div>
  );
}
