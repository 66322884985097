import React from 'react'

export default function Seo_pricing() {
  return (
    <>
        
      <section class="app-price section-padding">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
              <div class="sec-head text-center">
                <h6 class="wow fadeIn" data-wow-delay=".5s">Pricing Package</h6>
                <h3 class="wow color-font">Friendly Pricing Package for Seo</h3>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Custom Website
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    E-Commerce Website
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="pric-tables">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-md-5">
                        <div class="item active">
                          <div class="type text-center mb-15">
                            <h5>
                              Basic
                            </h5>
                          </div>
                          <div class="amount text-center mb-40">
                            <h3>
                              <span>£</span>
                              299
                            </h3>
                          </div>
                          <div class="order mb-40 text-center">
                            <a
                              href="https://buy.stripe.com/4gwbIY02W7IB9YQ28f"
                              target="_blank"
                              class="butn-gr rounded buton"
                            >
                              <span>Choose Package</span>
                            </a>
                          </div>
                          <div class="feat">

                            <ul  class="scrollbar_pricing">
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 10 Keywords Optimization</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 4 Keywords in top 10</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> Search Engine Submissions</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  2 Blogs Writing</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  4 Blog Posting Links</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  10 Blogs Social Bookmarking Links</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  1 Press Release Writing</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 2 Press Release Links</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-5">
                        <div class="item active">
                          <div class="type text-center mb-15">
                            <h5>
                              Standard
                              <br class="d-none d-lg-block" />
                            </h5>
                          </div>
                          <div class="amount text-center mb-40">
                            <h3>
                              <span>£</span>
                              499
                            </h3>
                          </div>
                          <div class="order mb-40 text-center">
                            <a
                              href="https://buy.stripe.com/4gwbIY02W7IB9YQ28f"
                              target="_blank"
                              class="butn-gr rounded buton"
                            >
                              <span>Choose Package</span>
                            </a>
                          </div>
                          <div class="feat">
                            <ul class="scrollbar_pricing">
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 25 Keywords Optimization</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  10 Keywords in Top 10</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> Search Engine Submissions</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  6 Blogs Writing</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  12 Blog Posting Links</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  20 Blogs Social Bookmarking Links</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 2 Press Release Writing</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  4 Press Release Links</li>
                            </ul>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-5">
                        <div class="item active">
                          <div class="type text-center mb-15">
                            <h5>
                              Professionals
                            </h5>
                          </div>
                          <div class="amount text-center mb-40">
                            <h3>
                              <span>£</span>
                              799
                            </h3>
                          </div>
                          <div class="order mb-40 text-center">
                            <a
                              href="https://buy.stripe.com/00gcN202W0g95IA009"
                              target="_blank"
                              class="butn-gr rounded buton"
                            >
                              <span>Choose Package</span>
                            </a>
                          </div>
                          <div class="feat">

                            <ul class="scrollbar_pricing">
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  50 Keywords Optimization</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  20 Keywords in Top 10</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 14 Blogs Writing</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 28 Blog Posting Links</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  30 Blogs Social Bookmarking Links</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  4 Press Release Writing</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  8 Press Release Links</li>
                              <li>  <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  4 Guest Blogging Links</li>
                            </ul>

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-5">
                        <div class="item active">
                          <div class="type text-center mb-15">
                            <h5>
                              Enterprise
                            </h5>
                          </div>
                          <div class="amount text-center mb-40">
                            <h3>
                              <span>£</span>
                              1499
                            </h3>
                          </div>
                          <div class="order mb-40 text-center">
                            <a
                              href="https://buy.stripe.com/00gcN202W0g95IA009"
                              target="_blank"
                              class="butn-gr rounded buton"
                            >
                              <span>Choose Package</span>
                            </a>
                          </div>
                          <div class="feat">


                            <ul  class="scrollbar_pricing">
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 100 Keywords Optimization</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 40 Keywords in Top 10</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 30 Blogs Writing</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 60 Blog Posting Links</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div> 40 Blogs Social Bookmarking Links</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  8 Press Release Writing</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  8 Press Release Links</li>
                              <li> <div>
                                  <i class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                      <path
                                        d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                      ></path>
                                    </svg>
                                  </i>
                                </div>  8 Guest Blogging Links </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="pric-tables">
                    <div class="pric-tables">
                      <div class="row">
                        <div class="col-lg-4 col-md-5">
                          <div class="item active">
                            <div class="type text-center mb-15">
                              <h5>
                                E-Commerce Startup
                                <br class="d-none d-lg-block" />
                                Package
                              </h5>
                            </div>
                            <div class="amount text-center mb-40">
                              <h3>
                                <span>£</span>
                                600
                              </h3>
                            </div>
                            <div class="order mb-40 text-center">
                              <a
                                href="https://buy.stripe.com/bIYfZebLE7IB1skdR7"
                                target="_blank"
                                class="butn-gr rounded buton"
                              >
                                <span>Choose Package</span>
                              </a>
                            </div>

                            <div class="feat">
                              <ul class="scrollbar_pricing">
                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Up-to 100 Products</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Content Management System (CMS)</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Mini Shopping Cart Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Payment Module Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Easy Product Search</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Dedicated designer &amp; developer</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unlimited Revisions</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Satisfaction Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Unique Design Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Money Back Guarantee *</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-5">
                          <div class="item active">
                            <div class="type text-center mb-15">
                              <h5>
                                E-Commerce Professional

                                <br class="d-none d-lg-block" />
                                Package
                              </h5>
                            </div>
                            <div class="amount text-center mb-40">
                              <h3>
                                <span>£</span>
                                1,394
                              </h3>
                            </div>
                            <div class="order mb-40 text-center">
                              <a
                                href="https://buy.stripe.com/3csaEU6rkd2V8UMaEW"
                                target="_blank"
                                class="butn-gr rounded buton"
                              >
                                <span>Choose Package</span>
                              </a>
                            </div>
                            <div class="feat">
                              <ul class="scrollbar_pricing">
                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Customized Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Up-to 500 Products</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Content Management System (CMS)</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Full Shopping Cart Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Payment Module Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Easy Product Search</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Product Reviews</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>5 Promotional Banners</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Team of Expert Designers &amp; Developers</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unlimited Revisions</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Satisfaction Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Unique Design Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Money Back Guarantee *</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-5">
                          <div class="item active">
                            <div class="type text-center mb-15">
                              <h5>
                                E-Commerce Elite
                                <br class="d-none d-lg-block" />
                                Package
                              </h5>
                            </div>
                            <div class="amount text-center mb-40">
                              <h3>
                                <span>£</span>
                                3,694
                              </h3>
                            </div>
                            <div class="order mb-40 text-center">
                              <a
                                href="https://buy.stripe.com/8wM28og1UaUN4EwcN6"
                                target="_blank"
                                class="butn-gr rounded buton"
                              >
                                <span>Choose Package</span>
                              </a>
                            </div>
                            <div class="feat">
                              <ul class="scrollbar_pricing">
                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>UNLIMITED Logo Design Concepts</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>By 6 Award Winning Designers</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Icon Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>UNLIMITED Revisions</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Print Media</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>
                                    Stationary Design (BusinessCard,Letterhead &amp; Envelope)
                                  </div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Invoice Design, Email Signature</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Bi-Fold Brochure (OR) 2 Sided Flyer Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Product Catalog Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Sign age Design (OR) Label Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>T-Shirt Design (OR) Car Wrap Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Website</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>E-Commerce Store Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Product Detail Page Design</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unique Banner Slider</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Featured Products Showcase</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Full Shopping Cart Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unlimited Products</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unlimited Categories</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Product Rating &amp; Reviews</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Easy Product Search</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Payment Gateway Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Multi-currency Support</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Content Management System</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Cutomer Log-in Area</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Mobile Responsive</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Social Media Plugins Integration</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Tell a Friend Feature</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Social Media Pages</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>
                                    Facebook , Twitter, YouTube, Google+ &amp; Pinterest Page
                                    Designs
                                  </div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Value Added Services</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Dedicated Account Manager</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>Unlimited Revisions</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>All Final File Formats</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Ownership Rights</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Satisfaction Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Unique Design Guarantee</div>
                                </li>

                                <li>
                                  <div>
                                    <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                                        ></path>
                                      </svg>
                                    </i>
                                  </div>
                                  <div>100% Money Back Guarantee *</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
