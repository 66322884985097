import React from 'react'
import { useNavigate } from 'react-router-dom';
import { scroller } from "react-scroll";
export default function Callaction() {
    const navigate = useNavigate();
  
    const scrollTo = (ref, id) => {
      navigate(ref);
      setTimeout(() => {
        scroller.scrollTo(id, {
          spy: true,
          smooth: true,
          offset: 50,
          duration: 500,
        });
      }, 100);
    };
    
  return (
    <>
           <section className="call-action section-padding sub-bg bg-img call_action" data-background="img/patrn.svg">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-lg-9">
                        <div className="content sm-mb30">
                            <h6 className="wow" data-splitting>Let’s Talk</h6>
                            <h2 className="wow" data-splitting>about your <br/> <b className="back-color">next project</b>.</h2>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-3 valign">
                        <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Contact"),
                                  (id = "top")
                                )
                              } className="butn dark curve wow fadeInUp" data-wow-delay=".5s"><span>Get In
                                Touch</span></a>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
