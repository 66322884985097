import React from 'react'

export default function Clients() {
  return (
    <>
     <section className="clients  pt-50 pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-3 brands">
                                    <div className="item no-bord wow fadeIn" data-wow-delay=".3s">
                                        <div className="img">
                                            <img src={require("../img/design_crepe.png")} alt=""/>
                                            <a href="http://www.designcrepe.com/" target="_blank" className="link"
                                                data-splitting>www.Designcrepe.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 brands">
                                    <div className="item no-bord wow fadeIn" data-wow-delay=".3s">
                                        <div className="img">
                                            <img src={require("../img/secondgenerationhealer.png")} alt=""/>
                                            <a href="https://secondgenerationhealer.com/" target="_blank" className="link"
                                                data-splitting>www.secondgenerationhealer.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 brands">
                                    <div className="item no-bord wow fadeIn" data-wow-delay=".3s">
                                        <div className="img">
                                            <img src={require("../img/lusion.png")} alt=""/>
                                            <a href="https://lusion.co/" target="_blank" className="link"
                                                data-splitting>www.lusion.co</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 brands">
                                    <div className="item no-bord wow fadeIn" data-wow-delay=".3s">
                                        <div className="img">
                                            <img src={require("../img/themezinho.png")} alt=""/>
                                            <a href="https://themezinho.net/" target="_blank" className="link"
                                                data-splitting>www.themezinho.net</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
