import React from 'react'

export default function Branding_about() {
  return (
    <>
         <header class="slider-stwo valign position-re">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="img">
                            <img src={require("../img/min-area.jpg")} alt=""/>
                        </div>
                    </div>
                    <div class="col-lg-7 valign">
                        <div class="cont">
                            <div class="sub-title mb-5">
                                <h6>Digital Marketing</h6>
                            </div>
                            <h1 class="mb-10 fw-600">IS YOUR BUSINESS GOING TO YOUR AUDIENCE?</h1>

                            <ul class="d-block">
                                <li class="mb-3">
                                    <div>
                                        <span class="icon pe-7s-arc">
                                            <div class="bord"></div>
                                        </span>
                                    </div>
                                    <div class="cont">
                                        <h6>Branding</h6>
                                        <p class="">Do you know what your audience want to see</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span class="icon pe-7s-help2">
                                            <div class="bord"></div>
                                        </span>
                                    </div>
                                    <div class="cont">
                                        <h6>Marketing</h6>
                                        <p>
                                            Are you pushing them where you want them to be? Instead ,Meet them where
                                            they are heading.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}
