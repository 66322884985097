import React from 'react'
import Banner from '../Components/Banner'
import Portfolio from '../Components/Portfolio'
import Seo_pricing from '../Components/Seo_pricing'
import Call_now from '../Components/Call_now'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'

export default function Seo() {
  return (
    <>
<Motion>


    <Banner text1={"SEARCH ENGINE OPTIMZATION"} text2={"Mobile use is growing faster than all of Google’s internal predictions."} text3={"Erich Schmidt"} text4={"Website"} image="linear-gradient(45deg, #12131999, #12131999), url(./img/seo_agency.jpg)" />
    <Portfolio/>
    <Seo_pricing/>
    <Call_now/>
</Motion>
    </>
  )
}
