import React from 'react';
import ReactDOM from 'react-dom/client';
import './Css/App.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
       <Navbar/>
    <App/>
      <Footer/>
  </Router>
);

