import React from 'react'

export default function Bussines_package() {
  return (
    <>
           <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 combo_main">
                        <div class="row justify-content-center">
                            <div class="col-lg-7 col-md-10">
                                <h1><span class="combo_pricing"><span class="combo_pricing_sign">£</span> <strong
                                            class="color-font"> 3999</strong></span> <br/>
                                    Yes. That’s all it takes for
                                    complete digital assets business at
                                    Inceptives</h1>
                            </div>
                            <ul class="combo_ul mt-50">
                                <li>

                                    <div class="packaging-lists part-2">
                                        <ul>
                                            <li class="heading"><span class="color-font">Logo Design</span> </li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>
                                                Unlimited Logo Concepts</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>
                                                Unlimited Revisions</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>
                                                File Formats (PSD, PDF, AI, JPEG, PNG)</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>
                                                Icon Design</li>
                                        </ul>
                                    </div>

                                    <div class="packaging-lists mt-45">
                                        <ul>
                                            <li class="heading heading2"><span class="color-font">Stationary</span></li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>
                                                Custom Stationery Designs</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i> MS
                                                Word Letterhead (Letterhead, Envelope, Business Card, Invoice)</li>
                                        </ul>
                                    </div>

                                    <div class="packaging-lists mt-45">
                                        <ul>
                                            <li class="heading"><span class="color-font">Social Media</span></li>
                                            <li class="d-flex align-items-baseline"> <i
                                                    class="fa fa-check mr-2"></i>Facebook Page Design</li>
                                            <li class="d-flex align-items-baseline"> <i
                                                    class="fa fa-check mr-2"></i>Twitter Page Design</li>
                                            <li class="d-flex align-items-baseline"> <i
                                                    class="fa fa-check mr-2"></i>YouTube Page Design</li>
                                            <li class="d-flex align-items-baseline"> <i
                                                    class="fa fa-check mr-2"></i>Google+ Page Design</li>
                                            <li class="d-flex align-items-baseline"> <i
                                                    class="fa fa-check mr-2"></i>Instagram Page Design</li>
                                        </ul>
                                    </div>





                                </li>
                                <li>
                                    <div class="packaging-lists mt-lg-0 mt-md-45">
                                        <ul>
                                            <li class="heading"><span class="color-font">Website</span></li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Unlimited Pages Website</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Conceptual and Dynamic Liquid Website</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Content Management System (CMS)</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Team of Expert Designers &amp; Developers</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Mobile Responsive (compatible with IOS, Android, Tablets etc)</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Custom Forms</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Lead Capturing Forms (Optional)</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Striking Hoover Effects</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Social Media Integration</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Search Engine Submission</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>15 Stock images</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>8 Unique Banner Designs</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Jquery Sliders</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Free Google Friendly Sitemap</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Complete W3C Certified HTML</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>WordPress (Add, delete Products, Pages, Pictures, Videos)</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Testing And Deployment</li>
                                            
                                        </ul>
                                    </div>
                                </li>
                                <li>

                                    <div class="packaging-lists ">
                                        <ul>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Cross Browser Compatible</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Unique Interface</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>SEO Friendly Design</li>
                                        </ul>
                                    </div>


                                    <div class="packaging-lists  mt-45">
                                        <ul>
                                            <li class="heading"><span class="color-font">Value Added Services</span></li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>All Final File Formats</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>Dedicated Account Manager</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>100% Ownership Rights</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>100% Satisfaction Guarantee</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>100% Unique Design Guarantee</li>
                                            <li class="d-flex align-items-baseline"> <i class="fa fa-check mr-2"></i>100% Money Back Guarantee.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="packaging-lists  mt-45">
                                        <ul>
                                            <li class="price">£3999 only</li>
                                            <li><a href="https://buy.stripe.com/dR6aEU7voe6Zfja14H" target="_blank" class="btn_order">Order Now</a></li>
                                            <li><a href="https://wa.me/message/WKUOKF47RO4RC1" target="_blank" class="btn_disc">Discuss Now</a></li>
                                            <li class="mt-3">+ £799 top up E-commerce website and CRM.</li>
                                        </ul>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
