import React from 'react'
import { Link } from 'react-router-dom'

export default function Service() {
  return (
    <div>        <section className="services bords section-padding ">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
                <div className="sec-head  text-center">
                    <h6 className="wow fadeIn" data-wow-delay=".5s">SERVICES</h6>
                    <h3 className="wow color-font">WHAT WE HAVE FOR YOU!</h3>

                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 wow fadeInLeft" data-wow-delay=".5s">
                <Link to="/Graphic_design" className="item-box md-mb50">
                    <span className="icon pe-7s-paint-bucket"></span>
                    <h6>Graphic Design,<br/> Video Editing & Animations</h6>
                    <p>There are three responses to a piece of design – yes, no, and WOW! Wow is the one to aim
                        for. <br/> <span className="color-font"> Milton Glaser</span> </p>
                    <div className="more-stroke"><span></span></div>
                </Link>
                
            </div>
            <div className="col-lg-4 wow fadeInLeft" data-wow-delay=".7s">
                <Link to="/Web_design" className="item-box md-mb50">
                    <span className="icon pe-7s-phone"></span>
                    <h6>Website & Mobile <br/> Application
                    </h6>
                    <p>Mobile use is growing faster than all of Google’s internal predictions.<br/> <span
                            className="color-font"> Erich Schmidt</span></p>
                    <div className="more-stroke"><span></span></div>
                </Link>
            </div>
            <div className="col-lg-4 wow fadeInLeft" data-wow-delay=".9s">
                <Link to="/Digital_marketing" className="item-box">
                    <span className="icon pe-7s-display1"></span>
                    <h6>Digital <br/> Marketing</h6>
                    <p>It’s not what you sell that matters as much as how you sell it! <br/> <span
                            className="color-font"> Brian Halligan</span></p>
                    <div className="more-stroke"><span></span></div>
                </Link>
            </div>
        </div>
    </div>
</section>
</div>
  )
}
