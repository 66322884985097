import React from 'react'
import Banner from '../Components/Banner'
import Branding_about from '../Components/Branding_about'
import Portfolio from '../Components/Portfolio'
import Call_now from '../Components/Call_now'
import Bussines_package from '../Components/Bussines_package'
import Testimonial from '../Components/Testimonial'
import Contact_from from '../Components/Contact_from'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'

export default function Combo() {
  return (
    <>
  <Motion>


        <Banner text1={"COMBO PACKAGES"} text2={"Our mission is to help brands Create their Unique identity Globally"} text3={"Erich Schmidt"} text4={"Combo"} image="linear-gradient(45deg, #12131999, #12131999),url(./img/banner_img_2.jpg)" />
        <Branding_about/>
        <Portfolio/>
        <Call_now/>
        <Bussines_package/>
        <Testimonial/>
        <Contact_from/>
  </Motion>
  
    </>
  )
}
