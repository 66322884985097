import React from 'react'

export default function Graphic_pricing() {
  return (
    <>
    
    <section class="app-price section-padding">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-10">

                        <div class="sec-head  text-center">
                            <h6 class="wow fadeIn" data-wow-delay=".5s"> Pricing Package</h6>
                            <h3 class="wow color-font">Friendly Pricing Packages </h3>

                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-10">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home" aria-selected="true">Graphic Design</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile" aria-selected="false">Custom Video Animation</a>
                            </li>

                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="pric-tables">
                                    <div class="row justify-content-center ">

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Branding Startup <br class="d-none d-lg-block"/>
                                                        </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 499</h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/14k5kAbLE7IBef65kp" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">
                                                    <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Logo Design</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>6 </strong> Unique Logo Concepts</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> FREE Icon </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> FREE Grayscale Copy </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div>Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>100%</strong> Ownership Right </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Print Media </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Stationery Design (Business Card, Letterhead, Envelope) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Website Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> <strong>5</strong> Pages Custom Business Website </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg></i>
                                                            </div>
                                                            <div> Complete Deployment </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Branding Plus <br class="d-none d-lg-block"/>
                                                        </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 999 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY6oEcPI1kdgnefZ4" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Logo Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div><strong>12</strong> Unique Logo Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Icon </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Grayscale Copy </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Ownership Right </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Print Material </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Stationery Design(Business Card,Letterhead,Envelope) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Flyer Design / Label Design </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Website Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Custom 5 Pages Professional Website </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Content Management System (WordPress) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Responsive Design (Mobile Compatible) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Complete Deployment </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Social Media Design
 </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Facebook Fanpage Splash Page + Cover Photo </div>
                                                        </li>

                                                    </ul>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Branding Classic <br class="d-none d-lg-block"/>
                                                        </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 1499 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/bIY6oEcPI1kdgnefZ4" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">

                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Logo Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div><strong>12</strong> Unique Logo Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Icon </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Grayscale Copy </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Ownership Right </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Print Material </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Stationery Design(Business Card,Letterhead,Envelope) </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Any Product of your Choice: <br/>
Bi-fold/Tri-fold Brochure / Menu Card / Corporate Profile </div>
                                                        </li>
                                                        

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Website Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>10+</strong> Page Website </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Content Management System (WordPress/Joomla) </div>
                                                        </li>
                                                      
                                                      
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Complete Deployment </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Search Engine Submission <br/>
                                                            Google, Bing, Yahoo and More
 </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Social Media Design
 </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Facebook Fanpage Splash Page + Cover Photo </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Twitter Background Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Google+ Profile Banners </div>
                                                        </li>

                                                    </ul>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-5">
                                            <div class="item active">
                                                <div class="type text-center mb-15">
                                                    <h5>Branding Ultimate <br class="d-none d-lg-block"/>
                                                    </h5>
                                                </div>
                                                <div class="amount text-center mb-40">
                                                    <h3><span>£</span> 1999 </h3>
                                                </div>
                                                <div class="order mb-40 text-center">
                                                    <a href="https://buy.stripe.com/6oE4gw6rkgf76ME28m" target="_blank" class="butn-gr rounded buton">
                                                        <span>Choose Package</span>
                                                    </a>
                                                </div>
                                                <div class="feat">
                                                    <ul class="scrollbar_pricing">
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Logo Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Infinite Unique Logo Concepts </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Icon </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> FREE Grayscale Copy </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Revisions </div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> <strong>100%</strong> Ownership Right </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Print Material </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Stationery Design({" "}Business Card,Letterhead,Envelope {" "})</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 500 Business Cards, Letterhead, Envelop Prints</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Any Product of your Choice:<br/>
                                                            Bi-fold/Tri-fold Brochure / Menu Card / Corporate Profile </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Website Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 10+ Page Website </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Content Management System (WordPress/Joomla)</div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Complete Deployment</div>
                                                        </li>

                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Social Media Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Facebook Fanpage Splash Page + Cover Photo </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Twitter Background Design </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Google+ Profile Banner </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Complete Storyboard, VoiceOver & Animation </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        

                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="pric-tables">
                                    <div class="pric-tables">
                                        <div class="row ">
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Startup Video
                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 209.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/14k00g9Dw5At1skcNb" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div class="feat">

                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 30 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Revisions </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Classic Video

                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 409.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/9AQeVag1Ugf7ef6dRg" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Premium Video <br class="d-none d-lg-block"/>
                                                            Package</h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 809.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/00g14k9Dwfb33As5kL" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 90 Second Video </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Sample Theme </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Storyboard </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice - Over &amp; Sound Effects </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 6 Weeks Delivery </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Unlimited Animation
                                                            <br class="d-none d-lg-block"/>
                                                            Package
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 1409.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/4gwbIYbLEfb3b2U9B2" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>

                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 120 Second Video </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Sample Theme </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Storyboard </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                         <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice - Over Sound Effects </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Professional 3D VFX

                                                            <br class="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 2009.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/aEU14k7vo9QJ7QI8wZ" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">
                                                        <ul class="scrollbar_pricing">
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 30s Duration - HD 1080 </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Professional Script </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Concept and Storyboards </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> 3D Modeling </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Texturing </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rigging </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Lighting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Camera Setting </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Rendering </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Compositing and Special VFX </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Music and Foley </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                           <li>
                                                            <div>
                                                                <i class="icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 448 512">
                                                                        <path
                                                                            d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                        </path>
                                                                    </svg>
                                                                </i>
                                                            </div>
                                                            <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-5">
                                                <div class="item active">
                                                    <div class="type text-center mb-15">
                                                        <h5>Elite 3D VFX
                                                            <br class="d-none d-lg-block"/>
                                                            Animation
                                                        </h5>
                                                    </div>
                                                    <div class="amount text-center mb-40">
                                                        <h3><span>£</span> 2509.99 </h3>
                                                    </div>
                                                    <div class="order mb-40 text-center">
                                                        <a href="https://buy.stripe.com/fZeeVa6rkaUN7QI3cH" target="_blank" class="butn-gr rounded buton">
                                                            <span>Choose Package</span>
                                                        </a>
                                                    </div>
                                                    <div class="feat">

                                                        <ul class="scrollbar_pricing">
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 60s Duration - HD 1080 </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Unlimited Edits/Revisions &amp; Concepts </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Professional Script </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Concept and Storyboards </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> 3D Modeling </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Texturing </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rigging </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Lighting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Camera Setting </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Rendering </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Compositing and Special VFX </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Music and Foley </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Custom Setting, 2 Characters &amp; Graphics </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Animation Effects &amp; Visualization </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Voice Over - All accents (M/F) </div>
                                                            </li>
                                                            <li>
                                                                <div>
                                                                    <i class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 448 512">
                                                                            <path
                                                                                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </div>
                                                                <div> Editing and Final Output </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>


                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
