import React from 'react'

export default function Call_now() {
  return (
    <div>
          <div className="call-to-action-wrapper position-re  text-white-wrapper  ptb--120">
            <div className="arrow_img">
                <img className="img-fluid" src={require("../img/Arrow.gif")} alt=""/>
            </div>
            <div className="arrow_img_alt">
                <img className="img-fluid" src={require("../img/Arrow.gif")} alt=""/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 call-to-action">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="inner "><span>READY TO DO THIS</span>
                                    <h2>Chat with us now on WhatsApp for custom package and free consultation</h2>
                                    <a className="butn-cust rounded buton float-lg-right float-none mt-4" href="https://wa.me/message/WKUOKF47RO4RC1"><span>Contact
                                            Us</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
