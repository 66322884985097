import React from 'react'
import Banner from '../Components/Banner'
import Portfolio from '../Components/Portfolio'
import Branding_about from '../Components/Branding_about'
import Call_now from '../Components/Call_now'
import Pricing_digital from '../Components/Pricing_digital'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'


export default function Digital_marketing() {
  return (
    <>
<Motion>
        <Banner text1={"DIGITAL MARKETING"} text2={"Good marketing makes the company look smart. Great marketing makes the customer feel smart."} text3={"Joebhai Chernov"} text4={"Website"} image="linear-gradient(45deg, #12131999, #12131999),url(./img/dg_img.jpg)" />
        <Branding_about/>
        <Portfolio/>
        <Pricing_digital/>
        <Call_now/>
  </Motion>  

    </>
  )
}
