import React from 'react'
import Portfolio from '../Components/Portfolio'

export default function Portfolio_page() {
  return (
    <>
    <header
        class="works-header fixed-slider valign sub-bg"
        style={{
          backgroundImage:
            "linear-gradient(45deg, rgba(18, 19, 25, 0.6), rgba(18, 19, 25, 0.6)), url('./img/banner_img_2.jpg'",
        }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-9 static">
              <div class="capt mt-50">
                <div class="parlx text-center">
                  <h1 class="color-font">OUR PORTFOLIO</h1>
                  {/* <p class="text-white">
                    Our mission is to help brands Create their Unique identity
                    Globally
                    <br /> <span class="color-font">Erich Schmidt</span>
                  </p> */}
                </div>
                <div class="bactxt  custom-font valign">
                  <span class="full-width">Combo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    <Portfolio/>
    </>
  )
}
