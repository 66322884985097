import React from 'react';


export default function Testimonial() {
  return (
    <>
         <section className="block-sec">
            <div className="background bg-img pt-100 pb-0 parallaxie parallaxie_section" data-background="img/bg-vid.jpg"
                data-overlay-dark="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">

                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="testim-box">
                                <div className="head-box">
                                    <h6 className="wow fadeIn" data-wow-delay=".5s">Our Clients</h6>
                                    <h4 className="wow fadeInLeft" data-wow-delay=".5s">What Client's Say?</h4>
                                </div>
                                <div className="slic-item wow fadeInUp" data-wow-delay=".5s">
                                    <div className="item">
                                        <p>Best service. Fast responsive. Very professional. Very affordable as well
                                            as very creative in any design. Understands concept and has attention
                                            to detail. Would highly recommend.</p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src={require("../img/clients/Jenny_Tafaj.png")} alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Jenny Tafaj</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    {/* <div className="item">
                                        <p>
                                            Excellent Service, quick, easy, efficient and my business logo now looks
                                            perfect. Would highly recommend and absolutely would use them again.</p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/Elisha_Purton.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Elisha Purton</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="item">
                                        <p>Hannan was great to work with, reasonable, patient and made as many
                                            changes as needed.Great to work with, reasonable and made as many
                                            changes as needed.
                                            </p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/Nikki.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Nikki</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="item">
                                        <p>Very talented, friendly and quick designers. They designed our menu for
                                            affordable price. Very happy with the result. Definitely will work with
                                            them again
                                            </p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/Erkin_Sali.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Erkin Sali</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="item">
                                        <p>Hannan has been very professional, helpful and patient. We are so
                                            happy with the logo he designed with us. We would 100% recommend.
                                            Thank you Hannan!
                                            </p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/Karen_Smyth.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Karen Smyth</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="item">
                                        <p>Hannan did an amazing job for my bands newest single art. Very
                                            professional and very quick in his work.
                                            Communication was excellent and extremely quick and price was very
                                            good.
                                            Would highly recommend!
                                            </p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/George_Mian.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">George Mian</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src={require("../img/5_star.png")} alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div> */}
                                    {/* <!-- <div className="item">
                                        <p>What an Amazing service from Hannan,
                                            from start to finish, you have created a amazing logo for me.
                                            Hannan constantly replied back to every message on the changes on
                                            the logo within 5 minutes, he made the process very fast and created
                                            my logo within two days, you are very talented in the art work. I am very
                                            pleased with it.
                                            Thank you again for creating a brand image for my business. You are
                                            the amazing!
                                            </p>
                                        <div className="info">
                                            
                                            <div className="img">
                                                <div className="img-box">
                                                    <img src="img/clients/Tarick.jpg" alt=""/>
                                                </div>
                                            </div>
                                            <div className="cont">
                                                <div className="author">
                                                    <h6 className="author-name ">Tarick</h6>
                                                
                                                </div>
                                                <div className="star_img mt-2">
                                                    <img className="img-fluid"  src="img/5_star.png" alt=""/>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div> --> */}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
