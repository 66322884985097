import React from 'react'
import Banner from '../Components/Banner'
import Branding_about from '../Components/Branding_about'
import Portfolio from '../Components/Portfolio'
import Branding_pricing from '../Components/Branding_pricing'
import Call_now from '../Components/Call_now'
import { motion} from 'framer-motion'
import Motion from '../Components/Wrapper/Motion'

export default function Branding() {
  return (
    <>
 
<Motion>

     <Banner text1={"BRANDING YOUR BUSSINES"} text2={"Good marketing makes the company look smart. Great marketing makes the customer feel smart."} text3={"Joebhai Chernov"} text4={"Website"} image="linear-gradient(45deg, #12131999, #12131999),url(./img/branding-hero.jpg)" />
     <Branding_about/>
     <Portfolio/>
     <Branding_pricing/>
     <Call_now/>
</Motion>

    </>
  )
}
